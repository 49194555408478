html {
  height: 100%;
  accent-color: #A374FF;
}

body {
  font-family: Patron, system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  font-size: var(--step-0);
  line-height: 1.25;
  height: 100%;
  overflow-x: hidden;
  background: #F7F7F7;
}

body.mobileDialogOpen {
  overflow-y: hidden;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

a {
  text-decoration: none;
  color: #683ac1;
}

#__next {
  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: stretch;
}

summary {
  cursor: pointer;
}

summary:focus {
  outline: none;
}

details summary::-webkit-details-marker,
details summary::marker {
  display: none;
  content: '';
}

/* Typography */

h1 {
  letter-spacing: -0.02em;
  line-height: 1.2;
  font-size: var(--step-5);
}

h2 {
  letter-spacing: -0.02em;
  line-height: 1.2;
  font-size: var(--step-3);
}

h3 {
  letter-spacing: -0.02em;
  line-height: 1.2;
  font-size: var(--step-2);
}

h4 {
  letter-spacing: -0.02em;
  line-height: 1.4;
  font-size: var(--step-1);
}

h5 {
  letter-spacing: -0.02em;
  line-height: 1.4;
  font-size: var(--step-0);
}

h6 {
  letter-spacing: -0.02em;
  line-height: 1.4;
  font-size: var(--step--1);
}

/* Flow */

.flow--2xs > * + * {
  margin-top: var(--space-2xs);
}

.flow--xs > * + * {
  margin-top: var(--space-xs);
}

.flow > * + *,
.flow--s > * + * {
  margin-top: var(--space-s);
}

.flow--l > * + * {
  margin-top: var(--space-l);
}

.flow--2xl > * + * {
  margin-top: var(--space-2xl);
}

.flow-x--2xs > * + * {
  margin-left: var(--space-2xs);
}

.flow-x--xs > * + * {
  margin-left: var(--space-xs);
}

.flow-x > * + *,
.flow-x--s > * + * {
  margin-left: var(--space-s);
}


/* Padding */

.pad,
.pad-y,
.pad-top {
  padding-top: var(--space-s);
}

.pad,
.pad-y,
.pad-bottom {
  padding-bottom: var(--space-s);
}

.pad,
.pad-x,
.pad-left {
  padding-left: var(--space-s);
}

.pad,
.pad-x,
.pad-right {
  padding-right: var(--space-s);
}

/* Margin */

.margin,
.margin-y,
.margin-top {
  margin-top: var(--space-s);
}

.margin,
.margin-y,
.margin-bottom {
  margin-bottom: var(--space-s);
}

.margin,
.margin-x,
.margin-left {
  margin-left: var(--space-s);
}

.margin,
.margin-x,
.margin-right {
  margin-right: var(--space-s);
}

/* Wrap */

.wrap {
  margin-right: auto;
  margin-left: auto;
  max-width: 1140px;
  padding-right: var(--space-s-l);
  padding-left: var(--space-s-l);
  width: 100%;
}

/* Width */

.width-100 {
  width: 100%;
}

/* Forms */

select,
textarea,
input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {
  width: 100%;
  padding: var(--space-2xs);
  border: 1px solid #B3B3B3;
  background: #FFF;
}

select {
  background: #FFF url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="10"><path fill="%23A374FF" fill-rule="nonzero" d="M9 7.59L1.7.29A1 1 0 10.3 1.71l8 8a1 1 0 001.4 0l8-8A1 1 0 1016.3.29L9 7.6z"/></svg>') center right 1rem no-repeat;
  background-size: 0.6em auto;
  appearance: none;
}

textarea {
  max-width: 100%;
  resize: vertical;
}

label[for] {
  cursor: pointer;
}

label:first-child {
  display: block;
  font-weight: bold;
  font-size: var(--step--1);
  margin-bottom: var(--space-3xs);
}

.checkbox {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: var(--space-2xs);
  align-items: center;
}

.checkbox label {
  margin-bottom: 0;
}

.checkbox input {
  height: 1em;
  width: 1em;
}

.button {
  --border-color: #000;
  --color: #FFF;
  --background: #000;


  border-color: var(--border-color);
  background: var(--background);
  color: var(--color);

  border: 2px solid var(--border-color);
  border-radius: 2em;
  padding: var(--space-2xs) var(--space-s);
  font-weight: bold;
  transition: 300ms background, 300ms border-color, 300ms color;
  text-align: center;
}

.button:hover {
  --border-color: #000;
  --color: #000;
  --background: #FFF;
}

.button:disabled {
  --border-color: #B3B3B3;
  --color: #B3B3B3;
  --background: #E9E8E7;
}

.button--secondary {
  --border-color: #000;
  --color: #000;
  --background: #FFF;
}

.button--secondary:hover {
  --border-color: #000;
  --color: #FFF;
  --background: #000;
}

/* Header */

.header {
  font-size: 22px;
  letter-spacing: -0.02em;
  border-bottom: 1px solid #B3B3B3;
}

.header strong {
  cursor: pointer;
}

.header strong:hover span {
  animation: label 1s infinite linear;
  transform-origin: 50% 100%;
  display: inline-block;
}

@keyframes label {
  33.333% {
    transform: rotate(-5deg);
  }

  66.666% {
    transform: rotate(5deg);
  }
}

/* Order page */

.address span:after {
  content: ', '
}

.address span:last-child:after,
.address span:empty:after {
  content: ''
}