@font-face {
  font-family: 'Antique Olive';
  src: url('/assets/fonts/AntiqueOlive-Bold.woff2');
  font-display: fallback;
}

@font-face {
  font-family: 'Patron';
  src: url('/assets/fonts/Patron-Bold.woff2');
  font-weight: bold;
  font-display: fallback;
}

@font-face {
  font-family: 'Patron';
  src: url('/assets/fonts/Patron-BoldItalic.woff2');
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Patron';
  src: url('/assets/fonts/Patron-Italic.woff2');
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Patron';
  src: url('/assets/fonts/Patron-Regular.woff2');
  font-display: fallback;
}

/* @link https://utopia.fyi/type/calculator?c=320,16,1.2,1140,16,1.2,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l */

:root {
  --step--2: clamp(0.69rem, calc(0.69rem + 0.00vw), 0.69rem);
  --step--1: clamp(0.83rem, calc(0.83rem + 0.00vw), 0.83rem);
  --step-0: clamp(1.00rem, calc(1.00rem + 0.00vw), 1.00rem);
  --step-1: clamp(1.20rem, calc(1.20rem + 0.00vw), 1.20rem);
  --step-2: clamp(1.44rem, calc(1.44rem + 0.00vw), 1.44rem);
  --step-3: clamp(1.73rem, calc(1.73rem + 0.00vw), 1.73rem);
  --step-4: clamp(2.07rem, calc(2.07rem + 0.00vw), 2.07rem);
  --step-5: clamp(2.49rem, calc(2.49rem + 0.00vw), 2.49rem);
}

/* @link https://utopia.fyi/space/calculator?c=320,16,1.125,1140,16,1.2,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l */

:root {
  --space-3xs: clamp(0.25rem, calc(0.25rem + 0.00vw), 0.25rem);
  --space-2xs: clamp(0.50rem, calc(0.50rem + 0.00vw), 0.50rem);
  --space-xs: clamp(0.75rem, calc(0.75rem + 0.00vw), 0.75rem);
  --space-s: clamp(1.00rem, calc(1.00rem + 0.00vw), 1.00rem);
  --space-m: clamp(1.50rem, calc(1.50rem + 0.00vw), 1.50rem);
  --space-l: clamp(2.00rem, calc(2.00rem + 0.00vw), 2.00rem);
  --space-xl: clamp(3.00rem, calc(3.00rem + 0.00vw), 3.00rem);
  --space-2xl: clamp(4.00rem, calc(4.00rem + 0.00vw), 4.00rem);
  --space-3xl: clamp(6.00rem, calc(6.00rem + 0.00vw), 6.00rem);

  /* One-up pairs */
  --space-3xs-2xs: clamp(0.25rem, calc(0.15rem + 0.49vw), 0.50rem);
  --space-2xs-xs: clamp(0.50rem, calc(0.40rem + 0.49vw), 0.75rem);
  --space-xs-s: clamp(0.75rem, calc(0.65rem + 0.49vw), 1.00rem);
  --space-s-m: clamp(1.00rem, calc(0.80rem + 0.98vw), 1.50rem);
  --space-m-l: clamp(1.50rem, calc(1.30rem + 0.98vw), 2.00rem);
  --space-l-xl: clamp(2.00rem, calc(1.61rem + 1.95vw), 3.00rem);
  --space-xl-2xl: clamp(3.00rem, calc(2.61rem + 1.95vw), 4.00rem);
  --space-2xl-3xl: clamp(4.00rem, calc(3.22rem + 3.90vw), 6.00rem);

  /* Custom pairs */
  --space-s-l: clamp(1.00rem, calc(0.61rem + 1.95vw), 2.00rem);
}

:root {
  --white: #FFF;
  --black: #000;
  --lightest-grey: #F7F7F7;
}